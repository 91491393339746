<template>
  <el-row>
    <el-col :span="24">
      <div class="main">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="概述" name="first">
            <img :src="one" alt="1" class="resize_img" style="max-height: 1400px;">
            <img :src="two" alt="2" class="resize_img" style="max-height: 1400px;">
            <img :src="three" alt="3" class="resize_img" style="max-height: 1400px;">
            <img :src="four" alt="4" class="resize_img" style="max-height: 1400px;">
            <img :src="five" alt="5" class="resize_img" style="max-height: 1400px;">
            <img :src="six" alt="6" class="resize_img" style="max-height: 1400px;">
            <img :src="seven" alt="7" class="resize_img" style="max-height: 1400px;">
            <img :src="eight" alt="8" class="resize_img" style="max-height: 1400px;">
            <div class="con_foot">
              <p style="float: left; font-size: 30px; color: #fff;">您可能还喜欢</p>
              <el-link style="float: right; font-size: 16px; color: #fff;" :underline="false" href="#">查看更多 ></el-link>
              <div class="content">
                <div class="con_item">
                  <div>
                    <img :src="one_one" alt="1" class="resize_img">
                  </div>
                  <p>CG-2手机支架</p>
                  <el-link style="font-size: 16px; color: #0088FE;" :underline="false" href="#">查看更多 ></el-link>
                </div>
                <div class="con_item">
                  <div>
                    <img :src="one_two" alt="2" class="resize_img">
                  </div>
                  <p>G3胎压监测</p>
                  <el-link style="font-size: 16px; color: #0088FE;" :underline="false" href="#">查看更多 ></el-link>
                </div>
                <div class="con_item">
                  <div>
                    <img :src="one_three" alt="3" class="resize_img">
                  </div>
                  <p>XR-3记录仪</p>
                  <el-link style="font-size: 16px; color:#0088FE;" :underline="false" href="#">查看更多 ></el-link>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="常见问题" name="second">
            <el-row>
              <el-col :span="24" style="padding: 0 25%;">
                <h1 class="title">常见问题</h1>
                <div class="info">
                  <div class="menu">
                    <el-input v-model="input" placeholder="搜索"></el-input>
                    <el-collapse v-model="activeNames" @change="handleChange">
                      <el-collapse-item title="AIO-5" name="1">
                        <div class="menu_item">
                          <el-link href="#/home/cg2/one" :underline="false">OBD常见问题</el-link>
                        </div>
                        <div class="menu_item">
                          <el-link href="#/home/cg2/two" :underline="false">qq音乐问题</el-link>
                        </div>
                        <div class="menu_item">
                          <el-link href="#/home/cg2/three" :underline="false">导航常见问题</el-link>
                        </div>
                        <div class="menu_item">
                          <el-link href="#/home/cg2/four" :underline="false">手机投屏问题</el-link>
                        </div>
                        <div class="menu_item">
                          <el-link href="#/home/cg2/five" :underline="false">胎压常见问题</el-link>
                        </div>
                        <div class="menu_item">
                          <el-link href="#/home/cg2/six" :underline="false">行车记录问题</el-link>
                        </div>
                        <div class="menu_item">
                          <el-link href="#/home/cg2/seven" :underline="false">音频及手机互联问题</el-link>
                        </div>
                        <div class="menu_item">
                          <el-link href="#/home/cg2/eight" :underline="false">其它问题</el-link>
                        </div>
                      </el-collapse-item>
                    </el-collapse>
                  </div>
                  <div class="content">
                    <router-view />
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="规格" name="third">
            <img :src="img_top" alt="1" class="aio_img_resize" style="margin-top: 60px; max-width: 500px;">
            <ul class="specs" style="padding: 0 25%;">
              <li>
                <span class="title">概要</span>
                <div class="info">
                  <div>
                    <p style="color: grey;">产品型号</p>
                    <p style="color: #fff;">CG-2</p>
                  </div>
                  <div>
                    <p style="color: grey;">输入参数</p>
                    <p style="color: #fff;">11-18V 3A</p>
                  </div>
                  <div>
                    <p style="color: grey;">输出参数</p>
                    <p style="color: #fff;">无线15W MAX
                      有线25W MAX</p>
                  </div>
                  <div>
                    <p style="color: grey;">无线传输距离</p>
                    <p style="color: #fff;">≤ 4MM</p>
                  </div>
                  <div>
                    <p style="color: grey;">工作环境温度</p>
                    <p style="color: #fff;">-10℃ - 40℃</p>
                  </div>
                  <div>
                    <p style="color: grey;">产品尺寸</p>
                    <p style="color: #fff;">86x76x43mm</p>
                  </div>
                </div>
              </li>
              <li>
                <span class="title">包装清单</span>
                <div class="info">
                  <div>
                    <p style="color: #fff;">CG手机支架 x1</p>
                  </div>
                  <div>
                    <p style="color: #fff;">管夹变径套 x3</p>
                  </div>
                  <div>
                    <p style="color: #fff;">安装工具 x4</p>
                  </div>
                  <div>
                    <p style="color: #fff;">固定管夹 x1</p>
                  </div>
                  <div>
                    <p style="color: #fff;">说明书 x1</p>
                  </div>
                  <div>
                    <p style="color: #fff;">连接线 x1</p>
                  </div>
                </div>
              </li>
            </ul>
          </el-tab-pane>
          <el-tab-pane label="购买" name="fourth"></el-tab-pane>
        </el-tabs>
      </div>
    </el-col>
  </el-row>
</template>
<script>
export default {
  data() {
    return {
      input: '',
      activeName: 'first',
      activeNames: '',
      one: require('../../../public/images/CG2/1.png'),
      two: require('../../../public/images/CG2/2.png'),
      three: require('../../../public/images/CG2/3.png'),
      four: require('../../../public/images/CG2/4.png'),
      five: require('../../../public/images/CG2/5.png'),
      six: require('../../../public/images/CG2/6.png'),
      seven: require('../../../public/images/CG2/7.png'),
      eight: require('../../../public/images/CG2/8.png'),
      // night: require('../../../public/images/CG2/9.png')
      one_one: require('../../../public/images/aio5/1-1.png'),
      one_two: require('../../../public/images/aio5/1-2.png'),
      one_three: require('../../../public/images/aio5/1-3.png'),
      img_top: require('../../../public/images/CG2/tab2/支架-场景11.2@2x.png')
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event)
    },
    handleChange() {}
  }
}
</script>
<style lang="less" scoped>
.main {
  background: #000;
}
// tab分栏
::v-deep .el-tabs__nav {
  // float: right;
  // margin-right: 10%;
  left: 55%;
  // transform: translateX(-30%);
}
::v-deep .el-tabs__nav-wrap::after {
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
}
::v-deep .el-tabs__nav-wrap::before {
  content: 'CG-2手机支架';
  display: block;
  position: absolute;
  top: 7px;
  left: 25%;
  color: #fff;
}
::v-deep .el-tabs__item {
  color: rgba(255, 255, 255, 0.3);
}
::v-deep .el-tabs__item.is-active {
  color: #fff;
}
::v-deep .el-tabs__item:hover {
  color: #fff;
}
::v-deep .el-tabs__active-bar {
  background-color: #fff;
}
.resize_img {
  width: 100%;
  height: auto;
  max-height: 800px;
  margin-top: -10px;
}
.specs li {
  position: relative;
  display: block;
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.6);
  text-align: left;
  span {
    position: relative;
    top: 15px;
    left: 20px;
    font-weight: 500;
    font-size: 30px;
    color: #fff;
  }
  .info {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-left: 30%;
    width: calc(100% - 200px);
    // height: 400px;
    // background-color: purple;
    div {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 50%;
      height: 100px;
      // background-color: pink;
      text-align: left;
      p {
        line-height: 40px;
      }
    }
  }
}
.con_foot {
  width: 100%;
  height: 850px;
  padding: 100px 20%;
  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 60px;
    .con_item {
      width: 30%;
      height: 450px;
      background: #fff;
      p {
        font-size: 20px;
        color: #111;
        line-height: 60px;
      }
      .el-link {
        line-height: 60px;
      }
      div {
        height: 300px;
        line-height: 60px;
        padding: 40px;
      }
    }
  }
}
.el-col {
  background-color: #f8f8f8;
}
.navbar {
  height: 40px;
  padding: 15px 25% 0;
  background: #dfdfdf;
}
.resize {
  width: 100%;
  height: 100%;
}
.title {
  margin-top: 80px;
  height: 60px;
  line-height: 60px;
  text-align: left;
  // border-bottom: 1px solid #dfdfdf;
  text-indent: 1em;
}
.info {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  .menu {
    flex: 1;
    // height: 300px;
    border-right: 1px solid #dfdfdf;
    ::v-deep .el-input__inner {
      width: 95%;
      background-color: #ebebeb;
      border: none;
      margin-top: 40px;
    }
    ::v-deep .el-collapse-item__header {
      font-size: 16px;
      background-color: #f8f8f8;
      border-bottom: 0;
    }
    ::v-deep .el-collapse-item__wrap {
      background-color: #f8f8f8;
      border-bottom: 0;
    }
    ::v-deep .el-collapse-item__content {
      padding: 0;
    }
    .menu_item {
      height: 40px;
      line-height: 40px;
      text-indent: 1em;
    }
    .menu_item:hover {
      background-color: #ebebeb;
      border-left: 2px solid #0088fe;
    }
  }
  .content {
    flex: 3;
  }
}
</style>